import { first } from "@/utils/arrays";
import { sleep } from "@/utils/promises";

export const requestSupportedLanguages = async (type, apiKey) => {

    const url = getUrlFromKey(apiKey) + "languages";

    const data = {
        method: "POST",
        headers: {
            "Authorization": `DeepL-Auth-Key ${apiKey}`,
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
            type,
        }),
    };

    const result = await fetch(url, data);

    if (!result.ok) {

        const err = new Error(`Something went wrong. Status: ${ result.status }`);
        err.status = result.status;

        throw err;
    }

    return result.json();
};

export const requestTranslations = (transUnits, apiKey, options) => {

    const url = getUrlFromKey(apiKey) + "translate";

    return transUnits.map((element, index) => ({
        id: element.id,
        promise: sleep(index * 50).then(() => executeTranslationRequest(element.source, apiKey, url, options)),
    }));
};

export const requestSingleTranslation = (transUnit, apiKey, options) => {

    const url = getUrlFromKey(apiKey) + "translate";

    return executeTranslationRequest(transUnit.source, apiKey, url, options);
};

const getUrlFromKey = apiKey => `/deeplProxy${ apiKey.endsWith(":fx") ? "-free" : ""}/v2/`;

const executeTranslationRequest = async (translationString, apiKey, url, options) => {

    const body = {
        source_lang: options["source-language"],
        target_lang: options["target-language"],
        text: translationString,
        tag_handling: "xml",
        non_splitting_tags: "g,x",
    };

    if (options["formality"]) {
        body.formality = options["formality"];
    }

    const data = {
        method: "POST",
        headers: {
            "Authorization": `DeepL-Auth-Key ${apiKey}`,
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(body),
    };

    // eslint-disable-next-line no-useless-catch
    try {

        const result = await fetch(url, data);

        if (!result.ok) {

            let msg = "Something went wrong."

            if (result.status.toString() === "456") {
                msg = "Quota exceeded!"
            }

            const err = new Error(`${ msg } Status: ${ result.status }`);
            err.status = result.status;

            throw err;
        }

        const obj = await result.json();

        return first(obj.translations)?.text;
    } catch (e) {

        throw e;
    }
};
